import React from 'react'
import Link from '../utils/link'
import { Wello } from './icons'

const Header = ({ location }) => {

  const [bottom, setBottom] = React.useState(undefined)

  React.useEffect(() => {
    if (location.pathname === '/coming-soon/' && window.innerWidth < 900) {
      setBottom(window.innerHeight - 135 + 'px')
    } else {
      setBottom(undefined)
    }
  }, [location])

  let props = {
    onClick: () => {
      const logo = document.querySelector('.header__logo')
      logo.classList.remove('animate')
    }
  }

  return (
    <>
      <header className='header'>
        <div className='header__inner'>
          <nav className='header__nav'>
            <ul>
              <li><Link to='/coming-soon/' {...props}>Coming Soon</Link></li>
              <li><Link to='/updates/' {...props}>Updates</Link></li>
              <li><Link to='/contact/' {...props}>Contact</Link></li>
            </ul>
          </nav>
        </div>
      </header>
      <div className='header__logo' style={{ bottom }}>
        <Link to='/'>
          <Wello color='#EDBB2D' />
        </Link>
      </div>
    </>
  )
}

export default Header