import React from 'react'
import PropTypes from 'prop-types'

import layoutQuery from "../hooks/use-layout-query"

import '../assets/scss/main.scss'

import Header from '../components/header'

const pairings = [
  {
    location: '/', bg: '#F1EB8D', text: '#EDBB2D',
    blocks: [[50, 50, 50],[50, 50, 50]],
  },
  {
    location: '/updates/', bg: '#FBB36D', text: '#F26124',
    blocks: [[440, 250, 160],[50, 70, 100]],
  },
  {
    location: '/contact/', bg: '#A2DEF9', text: '#268ECD',
    blocks: [[400, 320, 240],[100, 75, 50]],
  },
  {
    location: '/coming-soon/', bg: '#BDDC90', text: '#4FBFA3',
    blocks: [[100, 100, 100],[50, 50, 50]],
  },
  {
    location: '/privacy-policy/', bg: '#FBB36D', text: '#F26124',
    blocks: [[100, 100, 100],[50, 50, 50]],
  },
]

const Layout = ({ children, location }) => {
  const data = layoutQuery()

  const [pairing, setPairing] = React.useState(pairings.find(pairing => pairing.location === location.pathname) || pairings[0])
  const [isMobile, setIsMobile] = React.useState(true)

  React.useEffect(() => {
    const newPairing = pairings.find(pairing => pairing.location === location.pathname)
    setPairing(newPairing)
    setIsMobile(window.innerWidth < 900)

    document.documentElement.style.setProperty('--vh', (window.innerHeight / 100) + 'px')

    if (newPairing && typeof document !== `undefined`) {
      document.documentElement.style.setProperty('--bgColour', newPairing.bg)
      document.documentElement.style.setProperty('--textColour', newPairing.text)
    }

    setTimeout(() => {
      document.querySelectorAll('.header__logo')?.forEach(el => el.classList.add('animate'))
    }, 250)

    const handleResize = () => {
      document.documentElement.style.setProperty('--vh', (window.innerHeight / 100) + 'px')
      setIsMobile(window.innerWidth < 900)
    }
    document.addEventListener('resize', handleResize)
    return () => document.removeEventListener('resize', handleResize)

  }, [location])

  const { blocks } = pairing

  return (
    <>
      <Header location={location} data={data} />

      <main>{children}</main>

      <section className='blocks'>
        { [0,1,2].map((n, i) => (
          <div className='blocks__item' key={i}>
            <div 
              className='blocks__item-bar' 
              style={{
                width: isMobile ? null : blocks[1][i] + '%',
                height: isMobile ? blocks[0][i] <= 100 ? blocks[0][i] + '%' : blocks[0][i] + 'px' : null,
              }} 
            />
          </div>
        ))}
      </section>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
