import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import TransitionLink from "gatsby-plugin-transition-link"

const Link = ({ children, animate, ...props}) => {
  if (props.to && props.to.indexOf('//') === -1 && props.to.indexOf('mailto:') === -1 && props.to.indexOf('tel:') === -1) {

    //if (!!animate || true) return (<AniLink swipe direction={'right'} top={'exit'} hex={props.color || '#21242F'} {...props}>{ children }</AniLink>)

    //return (<AniLink {...props}>{ children }</AniLink>)
    return (<GatsbyLink {...props}>{ children }</GatsbyLink>)
  }

  return (
    <a {...props} href={props.to} target='_blank' rel='nofollow noopener noreferrer'>
      { children }
    </a>
  )
}

export default Link