import React from 'react'

const onMouseEnter = (e) => {
  // check if the event is triggered by a child element
  if (e.currentTarget !== e.target) return
  // check if the object has active class
  if (e.target.classList.contains('active')) return
  e.target.classList.add('active')
  setTimeout(() => {
    //e.target.classList.remove('active')
  }, 4600)
}

const onAnimationEnd = (e) => {
  //e.target.classList.remove('active')
  // check if element is last child
  if (e.target.parentElement.lastElementChild === e.target) {
    // find nearest parent svg node
    const svg = e.target.closest('svg')
    if (!svg) return;

    // check not the only one visible
    if (svg.closest('.letter-wave__side')?.querySelectorAll('svg.active')?.length > 1) {
      setTimeout(() => {
        svg.classList.remove('active')
      }, 3000)
    } else {
      setTimeout(() => {
        onAnimationEnd(e)
      }, 1000)
    }

  }
}

const props = { 
  onMouseEnter: onMouseEnter,
  onClick: onMouseEnter,
  onTouchStart: onMouseEnter,
  onTouchMove: onMouseEnter,
  onTouchEnd: onMouseEnter,
  onAnimationEnd: onAnimationEnd,
  onTransitionEnd: onAnimationEnd,
}

export const Hello = ({ color }) => (
  <svg viewBox="0 0 594 183" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path {...props} d="M177.8 5V181H144.6V105.3H66.2V181H33V5H66.2V76.4H144.6V5H177.8Z" fill="black" />
    <path {...props} d="M317.1 138.8C312.3 165.7 288.7 183 257.5 183C216 183 192.9 150.3 192.9 117.1C192.9 83.8999 213.5 52.2 255 52.2C296.5 52.2 316.6 83.1 316.6 113.3C316.6 117.6 316.4 121.3 316.1 123.9H222.6C224.9 144.3 237.9 157.3 257.5 157.3C273.3 157.3 283.6 150.8 286.9 138.7L317.1 138.8ZM223.3 103.6H285.7C284.4 87.4999 273.4 75.4 255 75.4C237.7 75.4 226.6 84.4999 223.3 103.6Z" fill="black" />
    <path {...props} d="M333.5 0H364.2V181H333.5V0Z" fill="black" />
    <path {...props} d="M382.7 0H413.4V181H382.7V0Z" fill="black" />
    <path {...props} d="M428.5 117.6C428.5 79.8999 456.2 52.2 494.9 52.2C533.6 52.2 561 79.8999 561 117.6C561 155.3 533.3 183 494.9 183C456.4 183 428.5 155.3 428.5 117.6ZM529.8 117.6C529.8 94.6999 515.7 78.9 494.8 78.9C473.9 78.9 459.6 94.6999 459.6 117.6C459.6 140.5 473.7 156.3 494.8 156.3C515.9 156.3 529.8 140.5 529.8 117.6Z" fill="black" />
  </svg>

)

export const Wello = ({ color }) => (
  <svg viewBox="0 0 594 183" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <g className="svg-wello">
        <path {...props} d="M245.4 5L192.9 181H160.2L122.7 56.6L85.2 181H52.8L0 5H33.7L70.1 132L108.4 5H136.8L175.2 132L211.9 5H245.4Z" fill="black" />
        <path {...props} d="M324.5 138.8H354.7C349.9 165.7 326.3 183 295.1 183C253.6 183 230.5 150.3 230.5 117.1C230.5 83.9 251.1 52.2 292.6 52.2C334.1 52.2 354.2 83.1 354.2 113.3C354.2 117.6 354 121.3 353.7 123.9H260.2C262.5 144.3 275.5 157.3 295.1 157.3C310.9 157.4 321.2 150.8 324.5 138.8ZM260.9 103.6H323.3C322 87.5 311 75.4 292.6 75.4C275.2 75.4 264.1 84.5 260.9 103.6Z" fill="black" />
        <path {...props} d="M398.4 181H367.7V0H398.4V181Z" fill="black" />
        <path {...props} d="M447.7 181H417V0H447.7V181Z" fill="black" />
        <path {...props} d="M594 117.6C594 155.3 566.3 183 527.9 183C489.4 183 461.5 155.3 461.5 117.6C461.5 79.9 489.2 52.2 527.9 52.2C566.6 52.3 594 79.9 594 117.6ZM562.8 117.6C562.8 94.7 548.7 78.9 527.9 78.9C507 78.9 492.7 94.7 492.7 117.6C492.7 140.5 506.8 156.3 527.9 156.3C549 156.4 562.8 140.5 562.8 117.6Z" fill="black" />
      </g>

      <path className="svg-place" d="M546.5 34.3C552.6 34.3 557.2 30.6 558 25.4H552.2C551.6 27.8 549.5 29.3 546.8 29.3C542.7 29.3 540.5 26.2 540.5 22C540.5 17.8 542.7 14.7 546.7 14.7C549.4 14.7 551.7 16.4 552.2 18.7H558C557.5 13.2 552.6 9.7 546.6 9.7C539.2 9.7 534.5 15.2 534.5 21.9C534.4 28.8 539.1 34.3 546.5 34.3ZM478.1 33.9H471.8V0.9H485C491.9 0.9 496 5.1 496 11.1C496 17.1 491.9 21.3 485 21.3H478V33.9H478.1ZM478.1 16.1H484.8C488.5 16.1 490 13.7 490 11.1C490 8.5 488.5 6.1 484.8 6.1H478.1V16.1ZM560.4 21.9C560.4 15.6 564.3 9.8 572 9.8C579.8 9.8 583.5 15.6 583.5 21.2C583.5 22 583.5 22.7 583.4 23.2H566C566.4 27 568.9 29.5 572.6 29.5C575.6 29.5 577.5 28.3 578.1 26H583.8C582.9 31 578.5 34.3 572.6 34.3C564.7 34.3 560.4 28.2 560.4 21.9ZM566.1 19.4H577.8C577.6 16.4 575.5 14.1 572 14.1C568.8 14.1 566.7 15.8 566.1 19.4ZM525.1 18.1C525.1 15.8 523.3 14.1 520.2 14.1C517.1 14.1 515.3 16 515.2 18.1H510C510.3 13.4 514.3 9.7 520.5 9.7C526.7 9.7 530.8 13.2 530.8 18.6V33.9H525.1V30.4C524.1 32.8 521.4 34.3 518 34.3C513.3 34.3 510.1 31.3 510.1 27.2C510.1 22.6 513.7 19.7 519 19.7H523.4C524.6 19.7 525.2 19 525.1 18.1ZM525.1 23.5H519.8C517.3 23.5 515.7 24.9 515.7 26.9C515.7 28.7 517.2 30 519.4 30C523 30 525.1 27.5 525.1 23.9V23.5ZM505.6 0H499.8V33.9H505.6V0Z" fill="black" />
    </g>
  </svg>
)

export const Place = () => (
  <svg viewBox="0 0 594 183" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M546.5 34.3C552.6 34.3 557.2 30.6 558 25.4H552.2C551.6 27.8 549.5 29.3 546.8 29.3C542.7 29.3 540.5 26.2 540.5 22C540.5 17.8 542.7 14.7 546.7 14.7C549.4 14.7 551.7 16.4 552.2 18.7H558C557.5 13.2 552.6 9.7 546.6 9.7C539.2 9.7 534.5 15.2 534.5 21.9C534.4 28.8 539.1 34.3 546.5 34.3ZM478.1 33.9H471.8V0.9H485C491.9 0.9 496 5.1 496 11.1C496 17.1 491.9 21.3 485 21.3H478V33.9H478.1ZM478.1 16.1H484.8C488.5 16.1 490 13.7 490 11.1C490 8.5 488.5 6.1 484.8 6.1H478.1V16.1ZM560.4 21.9C560.4 15.6 564.3 9.8 572 9.8C579.8 9.8 583.5 15.6 583.5 21.2C583.5 22 583.5 22.7 583.4 23.2H566C566.4 27 568.9 29.5 572.6 29.5C575.6 29.5 577.5 28.3 578.1 26H583.8C582.9 31 578.5 34.3 572.6 34.3C564.7 34.3 560.4 28.2 560.4 21.9ZM566.1 19.4H577.8C577.6 16.4 575.5 14.1 572 14.1C568.8 14.1 566.7 15.8 566.1 19.4ZM525.1 18.1C525.1 15.8 523.3 14.1 520.2 14.1C517.1 14.1 515.3 16 515.2 18.1H510C510.3 13.4 514.3 9.7 520.5 9.7C526.7 9.7 530.8 13.2 530.8 18.6V33.9H525.1V30.4C524.1 32.8 521.4 34.3 518 34.3C513.3 34.3 510.1 31.3 510.1 27.2C510.1 22.6 513.7 19.7 519 19.7H523.4C524.6 19.7 525.2 19 525.1 18.1ZM525.1 23.5H519.8C517.3 23.5 515.7 24.9 515.7 26.9C515.7 28.7 517.2 30 519.4 30C523 30 525.1 27.5 525.1 23.9V23.5ZM505.6 0H499.8V33.9H505.6V0Z" fill="black" />
    </g>
  </svg>
)